
<template>
  <Layout>
    <div class="clearfix"></div>

    <div class="row">
      <div class="col-12">
        <campaign-tab-wrapper>
          <campaign-tab
            title="Tab1"
            :items="campaignData"
            :fields="fields"
            responsive="sm"
            :busy="isBusy"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(actions)="row">
              <button class="action-btn" @click="editCampaign(row.item)">
                <i class="fas fa-edit"></i>
              </button>
            </template>

            <template #cell(campaign)="row">
              {{ row.item.plan.name }}
            </template>
            <template #cell(company)="row">
              {{ row.item.company.name }}
            </template>
            <template #cell(status)="row">
              {{ onStatus(row.item.status) }}
            </template>
            <template #cell(createdAt)="row">
              {{ moment(row.item.createdAt).format("lll") }}
            </template>
          </campaign-tab>
        </campaign-tab-wrapper>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { campaigns } from "@/config/api/campaigns";
import CampaignTab from "@/components/campaigns/CampaignTab";
import CampaignTabWrapper from "@/components/campaigns/CampaignTabWrapper";

export default {
  components: { Layout, CampaignTab, CampaignTabWrapper },

  data() {
    return {
      campaignData: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      fields: [],
    };
  },
  computed: {
    rows() {
      return this.campaignData.length;
    },
  },
  mounted() {},
  created() {
    this.getAllCampaigns();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCampaigns() {
      this.isBusy = true;
      const api = campaigns.list;
      this.generateAPI(api)
        .then((res) => {
          this.campaignData = res.data.message;
          this.totalRows = this.campaignData.length;
          console.log("Campaigns", res.data.message);
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    editCampaign() {},
    onStatus(status) {
      switch (status) {
        case "0":
          return;
        case "1":
          return "Campaign verified";
        case "2":
          return "Design uploaded";
        case "3":
          return "Design verified";
        case "4":
          return "Sticker printed";
        default:
          return "Campaign added";
      }
    },
  },
};
</script>